import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/Clubs/MathClub/2023/Treasure/1.jpg';
import p2 from 'assests/Photos/Clubs/MathClub/2023/Treasure/2.jpg';
import p3 from 'assests/Photos/Clubs/MathClub/2023/Treasure/3.jpg';
import p4 from 'assests/Photos/Clubs/MathClub/2023/Treasure/4.jpg';
import p5 from 'assests/Photos/Clubs/MathClub/2023/Treasure/5.jpg';
import p6 from 'assests/Photos/Clubs/MathClub/2023/Treasure/6.jpg';
import p7 from 'assests/Photos/Clubs/MathClub/2023/Treasure/7.jpg';
import p8 from 'assests/Photos/Clubs/MathClub/2023/Treasure/8.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { SidebarMath } from './components';
import Archive2022 from 'views/MathClub/components/Archive2022';


const Treasure = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                TREASURE HUNT
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 9-12   Date: 01 AUGUST 2023(Tuesday)
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Math Association organised a Treasure Hunt on 1 August 2023 for the students of classes 9 to 12 as an inter-house competition. The main objective of this event was to make learning Mathematics enjoyable, promoting teamwork and develop problem-solving skills.
                                    <br></br>
                                    Each team solved a series of questions which provided them the hidden clues spread across the campus. The students were provided an opportunity to display their mathematical skills and logical reasoning. The enthusiasm and happiness of solving the puzzles as a team was clearly evident on the faces of the participants.
                                    <br></br>
                                    The Math Treasure Hunt activity proved to be a resounding success, making math enjoyable for the students. It provided an unique platform to apply their mathematical knowledge practically, reinforcing concepts learnt in the classroom.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Mathematics is, in its own way, the poetry of logical ideas”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 400 : 300}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <SidebarMath/>
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Treasure;